@import '../common';

$media-height: 290px;

.post-story-modal {
  border-radius: $size-border-radius;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  max-width: 1512px;
  min-height: 100%;
  padding-bottom: 30px;
  width: 100vw;

  @media(min-width: $screen-sm) {
    height: initial;
    margin: 57px auto 0;
    min-height: calc(100vh - 57px);
  }

  @media only screen and (min-height: $story-modal-height) {
    min-height: auto;
  }

  @media only screen and (min-height: $story-modal-height) and (max-width: $screen-sm) {
    min-height: 100%;
  }

  .mobile-header {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    left: 0;
    padding-top: 20px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 100;

    .mobile-content {
      align-items: center;
      display: flex;

      svg {
        margin-left: 15px;
      }

      h4 {
        display: inline-block;
        font-weight: bold;
        margin: 0;
        text-align: center;
        width: calc(100% - 100px);
      }
    }

    .divider {
      border-bottom: 1px solid $color-border;
      margin-top: 20px;
      width: 100%;
    }
  }

  .gallery-lightbox {
    &__edit-container {
      display: flex;
    }

    &__title-container {
      margin-bottom: 8px;
      padding-right: 0;

      .title {
        font-size: 1.6em;
        font-weight: $font-weight-heavy;
        margin-left: 0;
      }

      .chq-pbn {
        display: flex;
      }

      svg {
        cursor: pointer;
        height: 24px;
        width: 24px;
      }
    }

    &__header-container {
      .QuickOptions {
        top: -48px;

        &--Send_btn {
          &:focus {
            outline: none;
          }

          svg {
            height: 30px;
            width: 30px;
          }

          path {
            fill: $color-white;
          }
        }

        .dropdown-menu {
          top: 15px;
        }
      }
    }
  }

  .gallery-lightbox-slider {

    &__full-header {
      border: 0;
      border-radius: 30px;
      height: 45px;
      left: 42%;
      position: absolute;
      top: calc(50% - -100px);
      width: 45px;

      path {
        fill: $color-white;
      }

      .title {
        align-items: center;
        display: flex;

        svg {
          background-image: url('../../images/chq-gradient-circle.png');
          background-position: center;
          background-size: cover;
          height: 35px;
          margin-right: 10px;
          padding: 8px;
          width: 35px;
        }

        h4 {
          margin: 0;
        }
      }

      .navigation {
        align-items: center;
        display: flex;

        .chq-pbn {
          @include square(22px);
        }

        .chq-pag {
          margin: 0 10px;

          &--bn-nav {
            display: none;
          }
        }
      }

      .actions {
        align-items: center;
        display: flex;

        .LandingPage-cmk {
          margin-right: 15px;

          path {
            fill: none;
          }
        }

        .Mapped-cmk {
          margin-right: 15px;

          path {
            fill: none;
          }

          .connection {
            path {
              fill: $color-orange;
            }
          }
        }

        .Approved-actions__Share {
          margin-left: 0;
          position: initial;

          .chq-abn {
            background-color: $color-linkedin-blue;
            color: $color-white;
            margin-bottom: 2px;
            margin-top: 0;
            padding: 5px 15px;

            svg {
              margin-right: 5px;
            }

            path {
              fill: $color-white;
            }
          }

          .dropdown-menu {
            top: -5px;
          }
        }

        .close-btn {
          @include square(30px);
          margin-left: 15px;

          svg {
            @include square(30px);
          }
        }
      }
    }
  }

  .Form-Container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    @media only screen and (min-width: $screen-sm) {
      flex-direction: row;
      padding-bottom: 60px;
    }

    .Loader-Container {
      align-items: center;
      background-color: $color-black-alpha-80;
      bottom: 0;
      color: $color-white;
      display: flex;
      flex-direction: column;
      font-weight: $font-weight-heavy;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: $z-index-high;

      p {
        margin-bottom: 0;
      }
    }

    &.disabled {
      pointer-events: none;
    }
  }

  .public-user {
    display: block !important;
  }

  .Shares-Column {
    align-items: center;
    display: flex;
    justify-content: end;
    left: 0;
    position: absolute;
    right: 60px;
    top: -50px;

    @media only screen and (min-width: $screen-sm) {
      flex-direction: column;
      justify-content: center;
      left: 40px;
      position: absolute;
      right: initial;
      top: initial;
    }

    .PromptShareOptions__Buttons {
      @media only screen and (min-width: $screen-sm) {
        flex-direction: column;
      }

      .chq-pbn  {
        @include square(45px);
        margin-bottom: 15px;
        padding: 5px;
      }

      .Slack-Image {
        @include square(24px);
      }
    }

    .PromptIcon {
      left: 15px;
      margin-bottom: 15px;
      position: absolute;

      @media only screen and (min-width: $screen-sm) {
        @include square(80px);
        margin-bottom: 30px;
        margin-right: 0;
        position: initial;
      }

      svg {
        @include square(35px);
      }
    }
  }

  .LeftColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 40px;
    width: 100%;

    @media only screen and (min-width: $screen-sm) {
      margin-bottom: 0;
      margin-top: 0;
      width: 50%;
    }

    @media(max-width: $screen-sm) {
      margin-top: 0;
    }

    .buttons-container {
      align-items: center;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 15px;
      width: 100%;

      @media only screen and (min-width: $screen-md) {
        flex-direction: row;
      }

      .chq-ffd {
        margin: 0 5px;
        position: initial;

        .chq-btn {
          margin: 0 0 5px;

          @media only screen and (min-width: $screen-md) {
            margin: 0;
          }
        }
      }

      .chq-btn {
        align-items: center;
        background-color: $color-dark-blue;
        border: 0;
        border-radius: $size-border-radius;
        bottom: 0;
        color: $color-white;
        display: flex;
        font-weight: $font-weight-heavy;
        justify-content: center;
        margin: 0 0 5px;
        overflow: hidden;
        padding: 20px 10px;
        position: initial;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media only screen and (min-width: $screen-md) {
          margin: 0 5px;
        }

        .chq-ffd--im--bt-bg--text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        svg {
          height: 22px;
          margin: 0 5px 0 0;
        }

        path {
          fill: $color-white;
        }

        &:hover {
          border: 0;
        }
      }

      .story-media-btn {
        margin: 0 0 7px;
        width: 100%;

        &:nth-child(3n) {
          width: 100%;
        }

        @media only screen and (min-width: $screen-sm) {
          width: calc(50% - 3.5px);
        }

        .chq-ffd--im {
          border: 0;
          margin: 0;
        }

        .chq-btn {
          margin: 0;
        }
      }

      .chq-ffd--im--ph {
        display: none;
        margin-top: -78px;
      }

      .chq-ffd--im {
        border: 0;
        cursor: pointer;
        margin: 0;
        min-height: 0;
        padding-top: 1px;
        position: relative;
        text-align: center;
      }

      .chq-ffd--im--bt-upl {
        align-items: center;
        background-color: #2c3e4f;
        border: 0;
        border-radius: 6px;
        bottom: 0;
        color: #fff;
        display: flex;
        font-weight: 600;
        justify-content: center;
        overflow: hidden;
        padding: 20px 10px;
        position: initial;
        text-overflow: ellipsis;
        white-space: nowrap;

        svg {
          height: 22px;
          margin: 0 8px 0  0;
        }

        path {
          fill: $color-white;
        }

        &:hover {
          border: 0;
        }
      }

      &--margin {
        margin-top: 30px;
      }
    }

    .InstructionsContainer {
      background-color: $color-white;
      border: 1px solid $color-light-blue;
      border-radius: $size-border-radius;
      display: flex;
      font-weight: $font-weight-normal;
      margin: 9px 0 25px;
      padding: 15px;

      svg {
        @include square(24px);
        margin-right: 10px;
        min-width: 24px;

        path {
          fill: $color-primary-blue;
        }
      }

      &-Text {
        -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
        -webkit-line-clamp: 2; // sass-lint:disable-line no-vendor-prefixes
        display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .StoryTrend-Summary__Btn {
        color: $color-primary-font;
        font-size: $font-size-normal;

        svg {
          @include square(18px);
          margin: 0 0 0 5px;
          min-width: 18px;
        }
      }
    }
  }

  .RightColumn {
    border-radius: $size-border-radius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;
    min-height: 73vh;
    position: relative;
    width: 100%;

    @media only screen and (min-height: 860px) {
      min-height: 628px;
    }

    @media only screen and (min-width: $screen-sm) {
      margin-bottom: 0;
      padding-left: 80px;
      width: 50%;
    }

    @media(max-width: $screen-sm) {
      min-height: unset;
    }

    .DisclaimerContainer {
      align-items: center;
      border: #cacaca solid 1px;
      border-radius: 6px;
      display: flex;
      margin-top: 12px;
      padding: 6px 12px;

      &-Text--Icon {
        @include square(22px);
        margin-right: 6px;

        path {
          fill: $color-rtx-red;
        }
      }

      &-premise-health {
        path {
          fill: $color-black;
        }
      }
    }

    &-Row {
      height: auto;
      min-height: 200px;

      @media(max-width: $screen-sm) {
        margin-top: 0 !important;
      }

      &.PostStoryMedia {
        max-height: $media-height;
        z-index: $z-index-high;

        @media(max-width: $screen-sm) {
          margin-bottom: 15px;
        }

        .StoryMedia {
          height: $media-height;
        }
      }

      &.LazyEditor-Container {
        margin-top: 45px;
      }
    }

    .Media-Iframe {
      height: 100%;
      min-height: $media-height;
    }

    .chq-ffd {
      position: relative;
      width: 100%;

      @media only screen and (min-width: $screen-sm) {
        position: absolute;
      }

      &--lb {
        margin-bottom: 0;
      }

      &--im {
        align-items: center;
        background-color: #f3f3f3;
        border: 0;
        border-radius: $size-border-radius;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin-bottom: 0;

        &:hover {
          box-shadow: none;
        }

        &--bt-bg {
          left: 0;
          position: absolute;
          right: 0;
        }

        .chq-ipv {
          left: initial !important;
          opacity: .5;
        }

        .Empty-Media {
          align-items: center;
          color: $color-secondary-font;
          display: flex;
          flex-direction: column;

          .PromptIcon {
            @include square(70px);
            background: none;

            svg {
              @include square(60px);
            }

            path {
              fill: $color-secondary-font;
            }
          }
        }

        .Video-Loader {
          align-items: center;
          color: $color-black-alpha-80;
          display: flex;
          flex-direction: column;
          font-weight: $font-weight-normal;
          max-width: 75%;

          .Percentage {
            font-weight: $font-weight-heavy;
          }
        }
      }

      &--bg-img {
        .chq-ffd--video {
          min-width: 100%;
        }
      }

      &--bg-img-container {
        margin-bottom: 0;

        .empty-container {
          background-color: $color-background-darker;
          border-radius: $size-border-radius;
          filter: none;
          opacity: 1;
          transform: none;

          .chq-ffd--im {
            &:hover {
              cursor: initial;
            }
          }
        }

        .image-container {
          .chq-ffd--im {
            &:hover {
              cursor: initial;
            }
          }
        }
      }
    }

    .PreviewNotWorking {
      &-Container {
        bottom: initial;
        left: 15px;
        padding: 0 10px;
        position: absolute;
        top: 15px;
        width: initial;
        z-index: $z-index-medium;
      }

      &-Btn {
        align-items: center;
        background-color: rgba(0, 0, 0, .8);
        border-color: transparent;
        border-radius: $size-border-radius;
        display: flex;
        justify-content: center;
        outline: 0;

        svg {
          margin: 0 3px 0 0;
        }

        &__Close {
          @include square(20px);
          align-items: center;
          background-color: $color-white;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          position: absolute;
          right: 0;
          top: 0;

          svg {
            @include square(12px);
            margin: 0;
          }

          path {
            fill: $color-black;
          }
        }
      }
    }

    .hidden {
      display: none;
    }

    .visible {
      display: block;
    }
  }

  .VideoOptions {
    bottom: initial;
    display: flex;
    flex-direction: column;
    width: initial;
    z-index: $z-index-medium;

    .chq-btn {
      align-items: center;
      background-color: rgba(0, 0, 0, .8);
      border-color: transparent;
      border-radius: $size-border-radius;
      display: flex;
      justify-content: center;
      outline: 0;
      padding: 5px 9px;

      svg {
        margin: 0;
        margin-right: 5px;
      }

      &:hover {
        border-width: 1px;
      }
    }

    .chq-ttp {
      &--bbl {
        bottom: initial;
        top: calc(100% + 22px);
      }

      &--tri {
        bottom: initial;
        top: -6px;
      }
    }
  }

  .VideoOptionContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 165px;
  }

  .image-container {
    .user-selected-image {
      .chq-ffd {
        &--im {
          &--bt-bg {
            background-color: rgba(0, 0, 0, .7);
            border-radius: 12px;
            color: $color-white;
            padding: 15px 26px;

            svg {
              background-color: initial;
              padding: 0;
            }
          }
        }
      }

      .chq-ipv {
        left: unset !important;
        opacity: 1;
      }
    }
  }

  .ImageSlider {
    background-color: #f3f3f3;
    height: 100%;
    position: relative;

    .chq-ffd--bg-img-container {
      display: none;

      &.active {
        display: block;
      }
    }

    &-Delete {
      left: 15px;
      position: absolute;
      top: 15px;
      z-index: $z-index-medium;

      .chq-btn {
        @include square(40px);
        align-items: center;
        background-color: rgba(0, 0, 0, .8);
        border-color: transparent;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        outline: 0;

        svg {
          margin: 0;
        }
      }
    }

    &-Pagination {
      bottom: 15px;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  .MediaOptions {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 20;
  }

  .ImageCrop {
    align-items: center;
    background-color: rgba(0, 0, 0, .8);
    border-color: transparent;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    margin-left: 8px;
    outline: 0;
    padding: 6px 5px 1px;
    width: fit-content;
    z-index: 20;

    path {
      fill: $color-white;
    }
  }

  .image-slider-arrow {
    @include square(35px);
    background: rgba(255, 255, 255, .8);
    border: 0;
    border-radius: 30px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, .5);
    padding: 6px;
    position: absolute;
    top: calc(50% - 28px);
    z-index: $z-index-low;

    &-right {
      padding-right: 4px;
      right: 15px;
    }

    &-left {
      left: 15px;
      padding-left: 2px;
    }
  }

  .Form-Column {
    display: flex;
    margin-bottom: 15px;
    width: 100%;

    .form {
      display: flex;
      flex-direction: column;
    }

    @media only screen and (min-width: $screen-sm) {
      margin-bottom: 0;
      padding-left: 30px;
      width: 50%;
    }

    .chq-ttp--bbl {
      left: 0;
    }
  }

  .creator {
    &-container {
      align-items: center;
      display: flex;
      margin-bottom: 20px;

      p {
        margin-bottom: 0;
      }
    }

    &-name {
      color: $color-link-blue;
      font-weight: $font-weight-heavy;
    }
  }

  .chq-pan--hd-pr {
    border-radius: $size-border-radius;
    display: flex;
    justify-content: center;
    padding: 0;
    position: relative;
    text-align: left;

    .User-Answers {
      align-items: flex-start;
      color: $color-dark-blue;
      display: flex;
      font-size: $font-size-large;
      left: 15px;
      position: absolute;
      text-align: left;
      top: 15px;
      width: 90%;

      @media only screen and (min-width: $screen-sm) {
        align-items: center;
        color: $color-white;
        left: calc(50% - 190px);
        text-align: center;
        top: -40px;
        width: initial;
      }

      svg {
        background-color: $color-dark-blue;
        border-radius: 20px;
        margin-right: 15px;
        min-width: 22px;
        padding: 3px;

        @media only screen and (min-width: $screen-sm) {
          background-color: $color-white;
        }
      }

      path {
        fill: $color-white;

        @media only screen and (min-width: $screen-sm) {
          fill: $color-dark-blue;
        }
      }
    }

    .chq-mdl--cl {
      opacity: 1;
      right: 15px;
      top: -50px;

      svg {
        height: 35px;
        opacity: .9;
        transition: opacity 300ms ease-in-out;
        width: 35px;
      }

      path {
        fill: $color-white;
      }
    }
  }

  .chq-pan--bd {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 63px 15px 15px;

    @media only screen and (min-width: $screen-sm) {
      margin-top: 0;
      overflow-y: auto;
      padding: 30px 30px 5px 150px;
    }

    @media only screen and (min-width: $screen-md) {
      padding: 30px 180px 5px 230px;
    }

    .chq-tmb {
      height: 40px;
      margin-right: 15px;
      min-width: 40px;
    }

    .chq-edi--sg {
      .chq-tmb {
        height: 50px;
        margin-right: 15px;
        min-width: 25px;
      }
    }

    .form {
      flex-grow: 1;

      .chq-edi {
        background-color: $color-white;
        border-color: $color-border-darker;
        border-radius: $size-border-radius;
        height: 100%;
        margin-bottom: 0;
        padding-bottom: 100px;

        .DraftEditor-root { // sass-lint:disable-block no-vendor-prefixes, no-duplicate-properties
          -ms-overflow-style: none; // IE 10+
          height: 168px;
          overflow: scroll;
          overflow: -moz-scrollbars-none; // Firefox

          &::-webkit-scrollbar {
            display: none; // Safari and Chrome
          }

          @media only screen and (min-height: $windows-screen-height) {
            height: 230px;
          }
        }

        div {
          &[data-contents] {
            min-height: 160px;
          }
        }

        &-fc {
          border-color: $color-primary-blue;
        }

        &--sg {
          .chq-tmb {
            height: 25px;
            margin-right: 15px;
            width: 25px;
          }
        }
      }

      .add-tags {
        position: relative;
        z-index: 101;
      }

      .chq-ffd {
        position: relative;

        &--lb {
          svg {
            height: 28px;
            width: 28px;
          }

          path {
            fill: $color-primary-blue;
          }
        }

        &--ctrl {
          font-size: 1.5em;
          font-weight: $font-weight-heavy;
          padding-left: 40px;

          &::placeholder {
            color: $color-primary-light-font !important;
          }

          &:focus {
            border-color: $color-primary-blue;
            box-shadow: inset 0 1px 1px $color-form-inset, 0 0 8px $search-bar-shadow;
          }
        }
      }

      .public-DraftEditorPlaceholder-inner {
        color: $color-secondary-light-font;
        font-weight: 200;
      }

      .share-with-field {
        margin-bottom: 0;

        .chq-ffd {
          &--lb {
            width: 100%;

            .chq-ttp {
              position: absolute;
              right: 10px;

              .chq-ttp--bbl {
                top: -80px;
                width: max-content;
              }
            }

            svg {
              height: 22px;
              margin-left: 10px;
              width: 22px;
            }

            path {
              fill: $color-primary-green;
            }
          }

          &--ctrl {
            align-items: center;
            border: 0;
            display: flex;
            flex-wrap: wrap;
            font-size: initial;
            margin-bottom: 5px;
            padding: 6px 12px;

            &:hover {
              box-shadow: none;
            }

            .chq-bdg {
              align-items: center;
              display: flex;
              flex-direction: row-reverse;
              width: initial;

              svg {
                margin: 0 0 0 6px;
              }

              .category-icon {
                height: 18px;
                margin: 0 6px 0 0;
                width: 18px;
              }
            }
          }

          &--sl {
            margin: 0;
            padding-left: 30px;
            padding-right: 30px;
            z-index: $z-index-medium;

            &--place {
              color: $color-primary-green;
            }

            &--opts {
              margin-left: -30px;
              max-height: 200px;
              overflow-y: auto;

              .option-icon {
                @include square(20px);
                min-width: 20px;

                g {
                  fill: $color-white;
                }
              }
            }

            &--caret {
              margin-right: 30px;
            }
          }
        }
      }

      &__PostStory {
        position: relative;
        z-index: 19;
      }
    }

    .PostBtn {
      margin-left: 15px;
      margin-top: 0;
      padding: 9px 30px;
      z-index: 100;

      &:hover {
        border: $color-primary-green solid 1px;
      }

      @media(max-width: $screen-sm) {
        border-radius: 20px;
        height: 32px;
        padding: unset;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 100px;
      }
    }
  }

  .chq-bdg {
    background-color: $color-background;
    border: 0;
    border-radius: $size-border-radius;
    font-weight: $font-weight-heavy;
    margin-right: 5px;
    padding: 5px 12px;
    width: initial;
  }

  .chq-pan--ft {
    align-items: center;
    background-color: $color-white;
    border-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 0;

    @media only screen and (min-width: $screen-sm) {
      bottom: 30px;
      flex-direction: row;
      left: 150px;
      right: 30px;
    }

    @media only screen and (min-width: $screen-md) {
      right: 180px;
    }

    .Prompts-Navigation {
      display: flex;
      justify-content: center;
      left: 0;
      margin-left: 0;
      position: initial;
      right: 0;
      width: 100%;

      @media only screen and (min-width: $screen-sm) {
        justify-content: start;
        margin-left: -20px;
        position: absolute;
      }

      @media only screen and (min-width: $screen-lg) {
        justify-content: center;
        margin-left: 0;
      }

      .chq-icon-pag {
        margin: 0;
      }
    }

    .NextButton {
      width: 132px;
      z-index: 100;

      @media(max-width: $screen-sm) {
        border-radius: 20px;
        height: 32px;
        padding: unset;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 80px;
      }
    }

    .Footer-Buttons {
      display: flex;
      margin-top: 10px;

      @media only screen and (min-width: $screen-sm) {
        margin-top: 0;
      }
    }

    .modal-footer-column {
      flex-grow: 1;
      margin-bottom: 0;
      margin-top: 0;
      max-width: 200px;
    }

    .Mapped-cmk {
      height: 100%;
      margin-top: 0;

      .chq-cmk {
        height: 100%;
      }
    }

    .slack-channel-field {
      bottom: 0;
      margin-bottom: 0 !important;
      margin-left: 15px;
      position: relative;

      .chq-ffd {
        margin-bottom: 0;
        margin-right: 10px;
        margin-top: 0;

        &--lb {
          margin-bottom: 0;
        }

        &--ctrl {
          border: 1px solid $color-border-darker;
          border-radius: $size-border-radius;
          font-size: $font-size-normal;
          font-weight: $font-weight-normal;
          margin-bottom: 0;
          padding-left: 40px;
          padding-right: 25px;
        }

        &--sl--opts {
          height: 200px;
          opacity: 0;
          overflow-x: hidden;
          overflow-y: scroll;
          top: -200px;
          transform: none;
          transition: visibility 0s, opacity .5s linear;
          visibility: hidden;

          .chq-pbn {
            overflow-wrap: anywhere;
          }

          &-open {
            animation: none;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .chq-ffd--sl--icon {
      max-width: 42px;
      padding: 8px;
    }
  }

  .Form-Question {
    &__Prefilled {
      display: none;
    }

    .chq-ffd {
      &--lb {
        position: absolute;
        top: 10px;
        z-index: $z-index-low;
      }

      &--sl--opts {
        max-height: 280px;
        overflow-y: scroll;
      }
    }
  }

  .chq-ffd--sl--opts {
    border-radius: $size-border-radius;
    max-height: none;
    overflow: initial;
    z-index: 1000;
  }

  .story-progressbar {
    border-radius: 0 0 $size-border-radius $size-border-radius;

    @media(max-width: $screen-sm) {
      height: 6px;
      position: absolute;
      top: 63px;
    }

    .progressbar-bar {
      border-radius: 0 0 0 $size-border-radius;
    }
  }

  .Responses {
    &-Container {
      margin-top: 30px;

      .Slider {
        .chq-pbn {
          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .Stories__Empty {
        align-items: center;
        background-color: rgba($color-primary-blue, .2);
        border-radius: $size-border-radius;
        display: flex;
        height: 180px;
        justify-content: center;
        width: 100%;

        &--image {
          margin-right: 15px;
          width: 100px;
        }

        &--paragraph {
          color: $color-secondary-font;
          font-weight: $font-weight-heavy;
          margin: 0;
        }
      }
    }

    &-Title {
      color: $color-secondary-font;
      font-size: $font-size-large;
      font-weight: $font-weight-heavy;
      margin-top: 0;
    }

    &-Stories {
      .Slider {
        height: 195px;
        padding-bottom: 0;
      }
    }
  }

  .Unsplash--Search {
    background-color: $color-background-darker;
    border-radius: $size-border-radius;
    padding: 10px 15px;

    // max-height value is used on the FindImagesOnUnsplash component,
    // if you change the value, you need to update at handleOnScroll
    .images-results {
      max-height: 470px;
    }
  }

  .UserInformation {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    margin-top: 63px;
    min-height: calc(100vh - 63px);
    overflow-y: scroll;
    padding: 15px 15px 60px;
    position: relative;

    @media(min-width: $screen-sm) {
      height: 735px;
      margin-top: 0;
      min-height: initial;
      overflow-y: initial;
      padding: 0;
    }

    &-Fields {
      padding: 0 0 45px;
      width: 100%;

      @media(min-width: $screen-sm) {
        margin-top: 20px;
        overflow-y: scroll;
        padding: 0;
      }
    }

    .Email {
      margin-bottom: 15px;
      position: relative;
      width: 100%;

      .PressEmailEnter {
        bottom: 32px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 10px;
        width: 65px;
      }

      .EmailField {
        .chq-sfx {
          bottom: unset;
          top: 33px;

          &:hover {
            cursor: not-allowed;
          }

          svg path {
            fill: $color-border-darker;
          }

          .chq-sfx-text {
            color: $color-border-darker;
            font-weight: $font-weight-heavy;
          }

          &--suffix-icon {
            @include square(32px);
            max-width: unset;
            transform: rotate(180deg);
          }
        }

        &--Valid {
          .chq-sfx {
            &:hover {
              cursor: pointer;
            }

            svg path {
              fill: $color-primary-green;
            }

            .chq-sfx-text {
              color: $color-primary-green;
            }
          }
        }

        &--Validated {
          .chq-sfx {
            &:hover {
              cursor: default;
            }

            &--suffix-icon {
              @include square(38px);
              transform: none;
            }
          }
        }
      }

      .DomainCheck {
        color: $color-red;
        font-size: .8em;
        margin: 0;
      }
    }

    .Content {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 0;
      max-width: 560px;
      width: 100%;

      @media(min-width: $screen-sm) {
        margin-top: 36px;
      }

      .Policies {
        margin-top: 24px;
      }

      .Form {
        width: 100%;

        .ImageField {
          input {
            visibility: visible;
          }
        }

        .MoreUserInfo {
          .chq-ffd--im--ph {
            svg {
              height: 140px;
              width: 140px;
            }

            path {
              fill: rgba(0, 0, 0, .6);
            }
          }

          .chq-ffd--im--bt {
            background-color: rgba(0, 0, 0, .6);
            left: 0;
            max-width: 220px;
            position: absolute;
            right: 0;
            top: 15px;
          }

          .NameField {
            margin-top: 24px;
          }
        }
      }

      .Submit {
        border-radius: 20px;
        padding: 4px 20px;
        position: fixed;
        right: 15px;
        top: 15px;
        z-index: 100;

        @media(min-width: $screen-sm) {
          border-radius: $size-border-radius;
          bottom: 0;
          padding: 9px 13px;
          position: absolute;
          right: 180px;
          top: initial;
        }

        @media(min-width: $screen-lg) {
          bottom: 0;
          right: 180px;
        }

        &:hover {
          padding: 3px 19px;

          @media(min-width: $screen-sm) {
            padding: 8px 12px;
          }
        }
      }

      .Back {
        bottom: 0;
        left: 180px;
        position: absolute;
        width: 132px;

        @media(min-width: $screen-sm) {
          bottom: 0;
          left: 15px;
        }

        @media(min-width: $screen-lg) {
          bottom: 0;
          left: 180px;
        }
      }
    }
  }
}

@keyframes chqSlideUp {
  from {
    transform: scale3d(1, 0, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(-1.1);
  }

  100% {
    transform: scaleY(-1);
  }
}
